import * as Sentry from '@sentry/nuxt'

const { sentryDsn, release, environment } = useRuntimeConfig().public

Sentry.init({
  // If set up, you can use your runtime config here
  // dsn: useRuntimeConfig().public.sentry.dsn,
  dsn: sentryDsn,
  release: 'sepac@' + release,
  environment,

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.0
})
